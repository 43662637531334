import React, { useContext, useState } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'
import Cart from '~/components/Cart'
import { Link } from 'gatsby'
import { CartCounter, Container, CartContainer, Wrapper } from './styles'
import { colors } from '../../utils/styles'

import Logo from '../../images/batch-1_logo-black.svg'
import { HiOutlineShoppingCart } from 'react-icons/hi'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = ({ siteTitle }) => {
  const [hasItems, quantity] = useQuantity()
  const [cartOpen, setCartOpen] = useState(false)

  const handleCart = () => {
    setCartOpen(!cartOpen)
  }

  return (
    <Wrapper>
      <Container>
        <Link to="/">
          <img src={Logo} width="80%" alt="sauce lah logo" />
        </Link>
        <CartContainer onClick={handleCart}>
          {hasItems && <CartCounter>{quantity}</CartCounter>}
          <HiOutlineShoppingCart fill="black" stroke={`${colors.green}`} />
        </CartContainer>
      </Container>
      <Cart open={cartOpen} setOpen={setCartOpen} />
    </Wrapper>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
