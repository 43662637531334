import styled from '@emotion/styled'

import { breakpoints, colors } from '../../utils/styles'

export const Wrapper = styled.div`
  font-family: 'Swear Text: Black Cilati';
  position: fixed;
  width: 100%;
  margin-bottom: 1.45rem;
  z-index: 10;

  @media (max-width: ${breakpoints.l}px) {
    top: 0;

    img {
      width: 50%;
    }

    svg {
      margin-top: 20px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;

  a {
    border-bottom: none;
  }

  @media (max-width: ${breakpoints.l}px) {
    padding: 0 20px;
  }
`

export const CartContainer = styled.div`
  color: black;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  font-size: 5rem;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 4rem;
  }

  &:hover {
    cursor: pointer;

    span {
      box-shadow: 0 0 0 black;
    }
  }
`

export const CartCounter = styled.span`
  position: absolute;
  right: -20px;
  top: -10px;
  display: inline-block;
  box-sizing: content-box;
  white-space: nowrap;
  font-size: 2rem;
  line-height: 0.9;
  text-align: center;
  font-variant-numeric: tabular-nums;
  min-width: 29px;
  min-height: 29px;
  border-radius: 50%;
  padding: 5px;
  background: ${colors.yellow};
  color: black;
  z-index: 1;
  border: 3px solid black;
  box-shadow: 3px 3px 0 black;
  transition: box-shadow 0.25s linear;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 1.5rem;
    right: -15px;
    top: 5px;
    min-width: 25px;
    min-height: 25px;
    padding: 4px;
    line-height: 0.9;
  }

  @media (max-width: ${breakpoints.l}px) {
    line-height: 1;
  }
`
