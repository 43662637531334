import React, { useContext, useLayoutEffect } from 'react'

import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'

import {
  CartContainer,
  Title,
  TotalContainer,
  FlexContainer,
  Right,
  Header,
  Close,
  Footer,
} from './styles'
import { ButtonContainer, Button, colors } from '~/utils/styles'
import { HiOutlineShoppingCart } from 'react-icons/hi'
import { HiX } from 'react-icons/hi'

const Cart = ({ open, setOpen }) => {
  useLayoutEffect(() => {
    if (open) {
      const originalStyle = window.getComputedStyle(document.body).overflow
      document.body.style.overflow = 'hidden'
      return () => (document.body.style.overflow = originalStyle)
    }
  }, [open])

  const {
    store: { checkout },
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl, '_self')
  }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  const isEmpty = checkout.lineItems.length === 0

  const handleClose = e => {
    e.preventDefault()
    setOpen(false)
  }

  return (
    <CartContainer open={open}>
      <Header>
        <Title>
          <HiOutlineShoppingCart fill="black" stroke={`${colors.green}`} />
          <h3>Cart</h3>
        </Title>
        <Close>
          <button onClick={handleClose}>
            <HiX fill={`${colors.green}`} />
          </button>
        </Close>
      </Header>
      <FlexContainer>
        {lineItems}
        <Footer>
          {!isEmpty && (
            <TotalContainer>
              <h4>Total</h4>
              <Right>
                <h4>${checkout.subtotalPrice}</h4>
                <span>+ shipping</span>
              </Right>
            </TotalContainer>
          )}
          <ButtonContainer>
            <Button
              onClick={handleCheckout}
              disabled={checkout.lineItems.length === 0}
              className="border-white"
            >
              Checkout
            </Button>
          </ButtonContainer>
        </Footer>
      </FlexContainer>
    </CartContainer>
  )
}

export default Cart
