import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import ContextProvider from '~/provider/ContextProvider'
import { Wrapper, FlexContainer } from './styles'

import { GlobalStyle } from '~/utils/styles'
import Navigation from '~/components/Navigation'
import Footer from '~/components/Footer'

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = ({ children }) => {
  return (
    <ContextProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>sauce lah ~ batch 4</title>
      </Helmet>
      <GlobalStyle />
      <StaticQuery
        query={query}
        render={data => (
          <>
            <Navigation siteTitle={data.site.siteMetadata.title} />
            <Wrapper>
              <FlexContainer>{children}</FlexContainer>
            </Wrapper>
            <Footer />
          </>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
