import styled from '@emotion/styled'

import { breakpoints, colors } from '../../../utils/styles'

export const Wrapper = styled.div`
  font-family: 'Vulf Sans';
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 2rem 0;

  img {
    margin: 0;
    padding: 0;
    width: 20%;
    border-radius: 10px;
    border: 3px solid white;
    box-shadow: 2px 2px 0 white;
  }

  button {
    font-size: 1rem;
    color: white;
    border-bottom: 1px solid ${colors.green};

    &:hover {
      border-bottom-color: ${colors.yellow};
    }
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`

export const Title = styled.div`
  font-family: 'Vulf Sans: Medium';
`

export const Left = styled.div`
  display: flex;
  align-items: center;
`

export const Right = styled.div`
  font-family: 'Vulf Sans: Medium';
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 5px;
  }
`

export const Size = styled.div`
  font-size: 1rem;
`
