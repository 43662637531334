import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import SwearTextBlackCilati from '../fonts/Swear_Text-Black_Cilati.woff'
import VulfSansRegular from '../fonts/Vulf_Sans-Regular.woff'
import VulfSansMedium from '../fonts/Vulf_Sans-Medium.woff'
import VulfSansBold from '../fonts/Vulf_Sans-Bold.woff'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const colors = {
  yellow: '#f2ff52',
  green: '#52fd45',
  orange: '#FF6F59',
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      @font-face {
        font-family: 'Swear Text: Black Cilati';
        src: url(${SwearTextBlackCilati}) format('woff');
      }

      @font-face {
        font-family: 'Vulf Sans';
        src: url(${VulfSansRegular}) format('woff');
      }

      @font-face {
        font-family: 'Vulf Sans: Medium';
        src: url(${VulfSansMedium}) format('woff');
      }

      @font-face {
        font-family: 'Vulf Sans: Bold';
        src: url(${VulfSansBold}) format('woff');
      }

      body {
        margin: 0;
        font-family: 'Vulf Sans', sans-serif;
      }

      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        font-size: 14px;
      }

      h3 {
        font-size: 2rem;
        font-family: 'Vulf Sans: Medium';
      }

      h6 {
        margin-top: 1.75rem;
      }

      ::selection {
        background: ${colors.yellow};
      }
      ::-moz-selection {
        background: ${colors.yellow};
      }

      button,
      input,
      select {
        /* reset */
        border: none;
        outline: none;
        background: none;
        margin: 0;
        padding: 0;
        border-radius: 0;
      }

      button {
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: black;
        border-bottom: 2px solid ${colors.green};

        &:hover {
          border-bottom-color: ${colors.yellow};
        }
      }
    `}
  />
)

export const Img = styled(Image)`
  height: calc(100% - 5px);
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  border-radius: 20px;
  border: 3px solid black;
  box-shadow: 5px 5px 0 black;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-areas: 'left . right';

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const Button = styled.button`
  font-family: 'Swear Text: Black Cilati';
  font-size: 2.5rem;
  color: black;
  background: ${colors.green};
  border: 3px solid black;
  box-shadow: 5px 5px 0 black;
  border-radius: 40px;
  padding: 10px 30px 14px;
  margin: 10px;
  margin-bottom: 0;
  transition: box-shadow 0.25s linear;

  &.border-white {
    border: 3px solid white;
    box-shadow: 5px 5px 0 white;

    &:hover {
      &:not([disabled]) {
        background: ${colors.yellow};
        box-shadow: 0 0 0 white;
      }
    }
  }

  &:disabled {
    cursor: default;
    opacity: 50%;
  }

  &:hover {
    &:not([disabled]) {
      background: ${colors.yellow};
      box-shadow: 0 0 0 black;
    }
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
