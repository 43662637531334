import styled from '@emotion/styled'

import { breakpoints } from '../utils/styles'

export const Wrapper = styled.div`
  max-width: 860px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: ${breakpoints.l}px) {
    margin: 40px;
    margin-bottom: 0;
    height: 100%;
    padding: 0;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
    margin: 0;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  footer {
    font-size: 1.3rem;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;

    @media (max-width: ${breakpoints.l}px) {
      padding-bottom: 40px;
    }

    @media (max-width: ${breakpoints.m}px) {
      padding: 20px 50px;
    }

    @media (max-width: ${breakpoints.s}px) {
      padding-top: 0;
      padding-bottom: 40px;
    }
  }
`
