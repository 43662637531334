import React, { useContext } from 'react'

import StoreContext from '~/context/StoreContext'
import { Wrapper, InfoContainer, Title, Size, Left, Right } from './styles'

const LineItem = props => {
  const { item } = props
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const variantImage = item.variant.image ? (
    <img src={item.variant.image.src} alt={item.title} />
  ) : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(option => `${option.value} `)
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id)
  }

  return (
    <Wrapper>
      <Left>
        {variantImage}
        <InfoContainer>
          <Title>{item.title}</Title>
          <div>
            {item.variant.title === !'Default Title' ? item.variant.title : ''}
          </div>
          <Size>{selectedOptions}</Size>
        </InfoContainer>
      </Left>
      <Right>
        {item.quantity}
        <button onClick={handleRemove}>remove</button>
      </Right>
    </Wrapper>
  )
}

export default LineItem
