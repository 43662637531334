import styled from '@emotion/styled'

import { breakpoints, colors } from '../../utils/styles'

export const CartContainer = styled.div`
  box-sizing: border-box;
  background: black;
  color: white;
  padding: 20px;
  border-left: 10px solid ${colors.green};
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 500px;
  z-index: 20;
  overflow: scroll;
  transition: transform 0.25s ease-in-out;
  transform: translate3d(${props => (props.open ? '0' : '100%')}, 0, 0);

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }

  h3,
  h4,
  p,
  span,
  div,
  button {
    ::selection {
      color: black;
    }
    ::-moz-selection {
      color: black;
    }
  }

  h3 {
    font-family: 'Swear Text: Black Cilati';
    font-size: 3rem;
    margin: 20px 10px;
  }

  h4 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const Close = styled.div`
  svg {
    font-size: 2.5rem;
    margin-top: 0;

    &:hover {
      fill: ${colors.yellow};
    }
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-top: 0;
    font-size: 3rem;
  }
`

export const TotalContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-family: 'Vulf Sans: Medium';
  margin-bottom: 10px;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-family: 'Vulf Sans';
    font-size: 1rem;
    margin-top: -4px;
  }
`

export const Footer = styled.div``
