import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <footer className="Footer">
    <div>
      <Link to="/">Shop</Link>
    </div>
    <div>
      <Link to="/about">About</Link>
    </div>
    <div>
      <a
        href="https://instagram.com/sauce.lah"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
    </div>
  </footer>
)

export default Footer
